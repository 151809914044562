.btn_wrapper {
  color: var(--brand-500);
  cursor: pointer;
}

.btn_icon {
  width: 1.25rem !important;
  height: 1.25rem !important;
}

.icon_margin {
  margin-right: 6px;
}

.popover {
  max-width: calc(100% - 5.5rem);
}

.popover > div {
  min-width: 100%;
}

.popover_container {
  margin-right: -12px;
  margin-top: 14px;
}

.btn_logout {
  border: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
