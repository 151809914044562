.message_response {
  color: var(--dark);
  background-color: var(--gray-100);
  border-bottom: 1px solid var(--gray-200);
}

.response {
  width: 100%;
}

.file_name {
  max-width: calc(100% - 3px);
}
