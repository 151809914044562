.message {
  border-bottom: 1px solid var(--gray-200);
}

.message.is_open {
  border-bottom-color: transparent;
}

.message_author {
  color: var(--text-color);
}

.message_text {
  color: var(--dark);
}

.new {
  background-color: var(--gray-100);
}

.new .message_author {
  font-weight: 600;
}

.buttonPreview {
  max-width: calc(100% - 16px - 16px - .25rem - .5rem);
}

.noShrink {
  flex-shrink: 0;
}

.buttonDownload {
  width: 16px;
  height: 16px;
}
