.image_wrap:last-child {
  margin-bottom: 0 !important;
}

.image {
  width: 100%;
  height: auto;
}

.label {
  color: var(--light);
  z-index: 1;
  transition: left var(--transition-time-base);
}

.label::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 1rem 1rem 0;
  bottom: -1rem;
  left: 0;
}

.label_not_finished {
  background-color: var(--primary-500);
}

.label_not_finished::before {
  border-color: transparent var(--primary-600) transparent transparent;
}

.label_finished {
  background-color: var(--success-500);
}

.label_finished::before {
  border-color: transparent var(--success-600) transparent transparent;
}

.label_danger {
  background-color: var(--danger-500);
}

.label_danger::before {
  border-color: transparent var(--danger-600) transparent transparent;
}

.label_muted {
  background-color: var(--muted-500);
}

.label_muted::before {
  border-color: transparent var(--muted-600) transparent transparent;
}

.label_success {
  background-color: var(--success-500);
}

.label_success::before {
  border-color: transparent var(--success-600) transparent transparent;
}

.label_warning {
  background-color: var(--warning-500);
}

.label_warning::before {
  border-color: transparent var(--warning-600) transparent transparent;
}

.landscape {
  width: 90%;
  max-width: 65.25rem;
}

.portrait {
  width: 80%;
  max-width: 43.75rem;
}

.landscape,
.portrait {
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2));
}

.placeholder {
  background-color: var(--light);
  position: relative;
  padding-top: 141.4%;
  /* A4 sheet aspect ratio
   * Calculation formula:
       297 / 210 ≈ 1.414
       1.414 * 100% = 141.4%

   * It is needed for the placeholder to maintain its proportions when changing the width.
  */
}

.placeholder.missing {
  background-image: url('/assets/img/document.svg');
  background-repeat: no-repeat;
  background-size: 160px 183px;
  background-position: top 10rem center;
}

.placeholder_content {
  width: 100%;
  height: 100%;
}

.placeholder_spinner {
  width: 10rem;
  height: 10rem;
  margin-top: 10rem;
}

.placeholder_text {
  text-align: center;
}

.placeholder.missing .placeholder_text {
  position: absolute;
  top: 21.5rem;
  left: 50%;
  transform: translateX(-50%);
}

.container:hover .buttons {
  opacity: 1;
}

.buttons {
  height: calc(100% - 2rem);
  z-index: 200;
  opacity: 0;
  transition: opacity var(--transition-time-base) ease-in;
}

.buttons ul {
  position: sticky;
  top: 0
}

.buttons_documentHeaderFixed ul {
  top: calc(var(--header-height) + 3.625rem) !important;
  /*
    3.625rem - fixed document footer
  */
}

.button_link {
  width: 2rem !important;
  height: 2rem !important;
  background-color: var(--light) !important;
  border: 2px solid transparent !important;
  border-radius: 50% !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5)) !important;
}

.button_icon {
  fill: var(--gray-500);
}

.button_link:not(.button_link_disabled):hover .button_icon,
.button_link:not(.button_link_disabled):focus .button_icon {
  fill: var(--primary-500);
}

.button_link:not(.button_link_disabled):focus {
  border: 2px solid var(--primary-500);
  color: var(--primary-500);
  filter: none;
}

.button_link:not(.button_link_disabled):active {
  background-color: var(--primary-500);
  filter: none;
}

.button_link:not(.button_link_disabled):active .button_icon {
  fill: var(--light);
}

.button_link_disabled {
  background-color: var(--gray-100);
  border: 1px solid var(--gray-200);
  filter: none;
  pointer-events: none;
}

.button_link_disabled .button_icon {
  fill: var(--gray-300);
}

/* New File */

.pdf_thumbnail {
  min-width: 200px;
  max-width: 200px;
  max-height: calc(100vh - var(--header-height) - 32px);
  transition:
    min-width var(--transition-time-base) ease-in,
    max-width var(--transition-time-base) ease-in;
}
.pdf_thumbnail_documentHeaderFixed {
  max-height: calc(100vh - var(--header-height) - 3rem - 3.625rem - 32px);
  /*
    3rem - fixed document header,
    3.625rem - fixed document footer,
    32px - toolbar_preview_container
  */
}

.scale_item, .scale_popover {
  min-width: 7rem;
}

.loader {
  width: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
}

.loader svg > rect {
  fill: var(--muted-300);
}

.loader_wrapper {
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 100;
  pointer-events: none;
}

.button_dropDown_scale {
  font-size: 9px !important;
}

.button_dropDown_scale span:nth-child(2) {
  display: none;
}

.button_dropDown_scale:hover span:first-child {
  color: var(--primary-500);
}

.button_dropDown_scale:active span:first-child {
  color: var(--light);
}

.toolbar_preview_container {
  height: 32px;
}

.toolbar_preview {
  z-index: 1;
}

.thumbnail_hideShow_icon svg {
  fill: var(--light);
  transition:
    fill var(--transition-time-base) ease-in,
    transform var(--transition-time-base) ease-in,
}

.thumbnail_hideShow_button {
  width: 24px;
  height: 24px;
  z-index: 1;
  transition: background-color var(--transition-time-base) ease-in;
  border-radius: 2px;
}

.thumbnail_hideShow_icon:hover,
.thumbnail_hideShow_icon:focus {
  background-color: var(--muted-400);
}

.thumbnail_hideShow_button_active {
  background-color: var(--muted-500);
}

.thumbnail_nextPrev_button {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  transition: background-color var(--transition-time-base) ease-in;
}

.thumbnail_nextPrev_button:hover,
.thumbnail_nextPrev_button:hover {
  background-color: var(--muted-400);
}

.thumbnail_nextPrev_button[disabled] {
  background-color: transparent;
  opacity: 0.6;
}

.thumbnail_controls {
  min-width: 140px;
  max-width: 170px;
  opacity: 1;
  transition:
    min-width var(--transition-time-base) ease-in,
    max-width var(--transition-time-base) ease-in,
    opacity var(--transition-time-base) ease-in,
    visibility var(--transition-time-base) ease-in;
}

.thumbnail_controls_close {
  min-width: 0;
  max-width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}

.pdf_thumbnail_close {
  min-width: 0;
  max-width: 0;
}

.thumbnails_wrapper {
  position: sticky;
  top: -16px;
  z-index: 1;
  transition: border-right var(--transition-time-base) ease-in
}

.hidden {
  visibility: hidden;
}

.thumbnails_wrapper_documentHeaderFixed {
  top: calc(var(--header-height) + 3.625rem - 16px);
  max-height: calc(100vh - var(--header-height) - 3rem - 3.625rem);
  /*
    3rem - fixed document header,
    3.625rem - fixed document footer,
  */
}

.thumbnail_pageInput {
  width: 44px;
  border-radius: 2px;
  border: 2px sloid var(--muted-200);
}

.thumbnail_pageInput input {
  text-align: center;
  border-radius: 2px;
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.scroll_fullHeight {
  height: 100% !important;
}

.wrapper_documentHeaderFixed {
  padding-top: 3.625rem;
}

.viewerWrapper {
  width: 100%;
  scrollbar-color: var(--gray-100) rgba(0, 0, 0, 0);;
  scrollbar-width: thin;
}

.viewerWrapper::-webkit-scrollbar-track {
  background-color: var(--light);
  border-radius: 0 !important;
}

.viewerWrapper::-webkit-scrollbar-thumb {
  height: 50px;
}


