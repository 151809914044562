.messages {
  width: 400px;
  top: var(--header-height);
  right: 0;
  height: 536px;
  background-color: var(--light);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);
}

.messages_header {
  background-color: var(--brand-500);
  color: var(--light);
  font-weight: bold;
}

.close,
.refresh {
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.8;
  transition: opacity var(--transition-time-base);
}

.close {
  right: 1rem;
}

.refresh {
  left: 1rem;
}

.close:hover,
.close:focus,
.close:active,
.refresh:hover,
.refresh:focus,
.refresh:active {
  opacity: 1;
}
