/* The order of the properties is important;
* in the case of combining selectors, the transition stops firing when closing messages
*/

.enter {
  opacity: 0;
  transform: scale(0.8);
  visibility: hidden;
}

.enterActive {
  transform: scale(1);
  transform-origin: 29px -50px;
  opacity: 1;
  visibility: visible;
  transition: all var(--transition-time-base) cubic-bezier(.34,1.25,.3,1);
}

.exit {
  opacity: 1;
  transform: scale(1);
  visibility: visible;
}

.exitActive {
  opacity: 0;
  transform: scale(0.8);
  visibility: hidden;
  transition: all var(--transition-time-base) cubic-bezier(.34,1.25,.3,1);
}
