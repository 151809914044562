.popover {
  width: 10rem;
}

.popover_horizontal {
  width: 5rem;
}

.button button {
  width: 10rem;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--border-radius-lg);
  border-color: var(--warning-500) !important;
}

.button button:disabled {
  border-color: transparent !important;
  background-color: var(--warning-200);
  pointer-events: none;
}

.button_open button {
  width: 10rem;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-top-left-radius: var(--border-radius-lg);
  border-top-right-radius: var(--border-radius-lg);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.button_horizontal button {
  width: 5rem;
  box-shadow: none;
}

.button:hover {
  border-color: var(--warning-600) !important;
}

.button:active,
.button:focus {
  border-color: var(--warning-500) !important;
}

.container {
  margin-top: 1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--border-radius-lg);
  border-bottom-right-radius: var(--border-radius-lg);
}

.popover a.item {
  color: var(--text-color);
  width: 100%;
  height: 100%;
}

.popover a.item:hover,
.popover a.item:focus,
.popover a.item:active,
.popover a.item_selected {
  font-weight: 400;
  color: var(--light)
}

.popover a.item:focus,
.popover a.item:hover,
.popover a.item_selected {
   background-color: var(--primary-500);
 }

.popover a.item:active {
   background-color: var(--primary-600);
 }

.popover .item:first-child {
  border-top-left-radius: var(--border-radius-md);
  border-top-right-radius: var(--border-radius-md);
}

.popover .item:last-child {
  border-bottom-left-radius: var(--border-radius-md);
  border-bottom-right-radius: var(--border-radius-md);
}
