.notice {
  border-bottom: 1px solid var(--gray-200);
  padding: 16px;
}

.notice:last-child {
  border-bottom: none;
}

.notice:hover {
  background-color: var(--info-50);
}

.notice:hover .actions {
  opacity: 1;
}

.notice p {
  margin: 0;
  color: var(--text-color);
  line-height: 16px;
}

.document_info {
  font-size: 12px;
}
