.wrapper {
  width: 290px;
  right: 2.5rem;
  border: 1px solid var(--gray-200);
  border-radius: var(--border-radius-lg);
}

.copy_button {
  border: 1px solid var(--gray-200);
  border-radius: var(--border-radius-base);
}

.expiration {
  width: 84px;
}

.notification {
  left: 50%;
  bottom: -1px;
  transform: translateX(-50%);
  border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
}
