.scroll {
  height: 400px;
  max-height: 400px;
}

.loader {
  width: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  z-index: 1;
}

.clear_block {
  padding: 12px 16px;
  border-top: 1px solid var(--gray-200);
}

.clear_btn {
  padding: 2px 6px !important;
  font-size: 11px;
}
