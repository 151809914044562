.content {
  margin-right: 5rem;
  max-width: 25rem;
}

.error_code {
  font-size: 2.25rem;
  line-height: 1.75rem;
}

.link,
.link:active,
.link:focus,
.link:hover {
  color: var(--light);
}
