#viewer .page {
  direction: ltr;
  position: relative;
  margin: 0 auto 20px;
  overflow: visible;
  box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.2);
}

#viewer .page canvas {
  display: block;
  margin: 0;
}

#viewer .canvasWrapper {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.textLayer {
  position: absolute;
  text-align: initial;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 1;
  line-height: 1;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  forced-color-adjust: none;
}

.textLayer span, .textLayer br {
  position: absolute;
  color: transparent;
  white-space: pre;
  cursor: text;
  transform-origin: 0 0;
}

.textLayer br::selection {
  color: transparent;
  background-color: transparent;
}

.thumbnailSelectionRing {
  transition: outline 0.3s ease;
}

.thumbnail.selected .thumbnailSelectionRing,
.thumbnailSelectionRing:hover {
  outline: 4px solid var(--muted-600);
}

#thumbnailsWrapper > a > .thumbnail {
  padding-bottom: 5px;
  padding-top: 5px;
}

.thumbnail:not([data-loaded]) .thumbnailSelectionRing {
  border: 1px dashed rgba(132, 132, 132, 1);
}

.thumbnailSelectionRing {
  margin: auto;
}

#viewer canvas[hidden] {
  display: none !important;
}

.textLayer .highlight {
  margin: -1px;
  padding: 1px;
  background-color: rgba(223,170,60, 0.4);
  border-radius: 4px;
}

.textLayer .highlight.appended {
  position: initial;
}

.textLayer .highlight.begin {
  border-radius: 4px 0 0 4px;
}

.textLayer .highlight.end {
  border-radius: 0 4px 4px 0;
}

.textLayer .highlight.middle {
  border-radius: 0;
}
