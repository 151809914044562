.content {
  position: relative;
  z-index: 5;
}

.list {
  max-height: 20rem;
}

.name {
  max-width: 34rem;
}

.name:hover {
  color: var(--brand-600);
}

.name:hover svg {
  fill: var(--brand-600);
}

.row {
  color: var(--text-color);
  white-space: nowrap;
  cursor: pointer;
}

.row:hover,
.row:hover small {
  text-decoration: none;
  /* !important is needed because the library class 'text-muted' also has it */
  color: var(--light) !important;
  background-color: var(--primary-500);
}

.row:hover svg {
  fill: var(--light);
}

.title_width {
  min-width: 18rem;
  max-width: 18rem;
  width: 18rem;
}

.title_width_noKppAndInn {
  min-width: 35rem;
  max-width: 35rem;
  width: 35rem;
}

.itn,
.cio {
  min-width: 9rem;
  max-width: 9rem;
  width: 9rem;
}

.title,
.itn,
.cio {
  padding:
    calc(var(--space-xs) - var(--space-xxxs))
    calc(var(--space-md) - var(--space-xxs))
    calc(var(--space-xs) + var(--space-xxxs))
    calc(var(--space-base) + var(--space-xs));
  line-height: var(--line-height-md);
}

.cio {
  padding-right: calc(var(--space-base) + var(--space-xs));
}

.search {
  /* in the library input width is 100% with !important */
  width: calc(100% - 2rem) !important;
}

.creation {
  border-top: 1px solid var(--gray-200);
}

.container {
  top: 2rem !important;
  width: 37.5rem;
}
