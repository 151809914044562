.logo_wrapper {
  min-width: 200px;
}

.logo_custom_wrapper img {
  height: 40px;
  max-height: 40px !important;
  object-fit: cover;
  max-width: 193px;
}

.logo_custom_wrapper span {
  justify-content: center;
}
