.scroll {
  height: 448px;
  max-height: 448px;
}

.loader {
  width: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}
