.background {
  scrollbar-width: none;
  height: 100%;
  overflow-y: hidden;
}

.background::-webkit-scrollbar {
  width: 0;
}

.controls {
  background-color: rgba(var(--text-color-rgb), .75);
  height: 3rem;
}

.image {
  width: 100%;
  height: auto;
}

.image_wrap {
  padding: 4rem 0 7rem;
}

.file_buttons {
  /*        btn    top    margin */
  top: calc(2rem + 1rem + 1rem) !important;
}

.landscape {
  width: 90%;
  max-width: 65.25rem;
}

.portrait {
  width: 80%;
  max-width: 43.75rem;
}

.landscape,
.portrait {
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2));
}

.placeholder {
  background-color: var(--light);
  position: relative;
  padding-top: 141.4%;
  /* A4 sheet aspect ratio
   * Calculation formula:
       297 / 210 ≈ 1.414
       1.414 * 100% = 141.4%

   * It is needed for the placeholder to maintain its proportions when changing the width.
  */
}

.placeholder.missing {
  background-image: url('/assets/img/document.svg');
  background-repeat: no-repeat;
  background-size: 160px 183px;
  background-position: top 10rem center;
}

.placeholder_content {
  width: 100%;
  height: 100%;
}

.placeholder_spinner {
  width: 10rem;
  height: 10rem;
  margin-top: 10rem;
}

.placeholder_text {
  text-align: center;
}

.placeholder.missing .placeholder_text {
  position: absolute;
  top: 21.5rem;
  left: 50%;
  transform: translateX(-50%);
}

.button {
  width: 2rem;
  height: 2rem;
  background-color: var(--light) !important;
  border: 2px solid transparent !important;
  border-radius: 50% !important;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2)) !important;
  z-index: 10;
}

.button_icon {
  fill: var(--gray-500) !important;
}

.button:not(.button_disabled):hover .button_icon,
.button:not(.button_disabled):focus .button_icon {
  fill: var(--primary-500) !important;
}

.button:not(.button_disabled):focus {
  border: 2px solid var(--primary-500) !important;
  color: var(--primary-500) !important;
  filter: none !important;
}

.button:not(.button_disabled):active {
  background-color: var(--primary-500) !important;
  filter: none !important;
}

.button:not(.button_disabled):active .button_icon {
  fill: var(--light) !important;
}

.button_disabled {
  background-color: var(--gray-100) !important;
  border: 1px solid var(--gray-200) !important;
  filter: none !important;
  pointer-events: none !important;
}

.button_disabled .button_icon {
  fill: var(--gray-300) !important;
}
